import "core-js/modules/es.regexp.exec.js";
import Mock from 'mockjs';
Mock.mock('/mock_api/customer/getCustomerList', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T08:59:49.198+0000"
    },
    "data": {
      "list": {
        "total": 9,
        "list": [{
          "customerId": "68728037306269696",
          "customerName": "四川嘉博文生物科技有限公司",
          "username": "CFCJ202009001",
          "province": 22,
          "city": 1930,
          "town": 49324,
          "customerAddress": "成都市双流区滨河路南三段",
          "produceScale": 60,
          "remarks": null,
          "liaison": {
            "liaisonId": "68728037314658304",
            "liaisonName": "叶先生",
            "phone": "18030771965",
            "master": 1,
            "remark": "",
            "createtime": "2021-12-07T07:40:41.000+0000",
            "customerId": "68728037306269696",
            "isdel": 0
          }
        }, {
          "customerId": "64634772042809344",
          "customerName": "钦州港市政管理有限公司",
          "username": "CFCJ202112001",
          "province": 20,
          "city": 1753,
          "town": 25189,
          "customerAddress": "钦州市钦南区锦绣大道",
          "produceScale": 60,
          "remarks": null,
          "liaison": {
            "liaisonId": "64634772055392256",
            "liaisonName": "张先生",
            "phone": "18322456698",
            "master": 1,
            "remark": "",
            "createtime": "2021-12-03T01:34:20.000+0000",
            "customerId": "64634772042809344",
            "isdel": 0
          }
        }, {
          "customerId": "66108274230951936",
          "customerName": "深圳市家家分类科技有限公司",
          "username": "CFCJ202010001",
          "province": 19,
          "city": 1607,
          "town": 47388,
          "customerAddress": "广东省深圳市龙华区白石龙路龙光.玖龙玺家家分类驿站",
          "produceScale": 60,
          "remarks": null,
          "liaison": {
            "liaisonId": "66108274243534848",
            "liaisonName": "郭站长",
            "phone": "176 0300 4322",
            "master": 1,
            "remark": "",
            "createtime": "2021-11-30T02:10:40.000+0000",
            "customerId": "66108274230951936",
            "isdel": 0
          }
        }, {
          "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
          "customerName": "广西力源宝科技有限公司",
          "username": "15277054449",
          "province": 20,
          "city": 1715,
          "town": 43115,
          "customerAddress": "南宁市洪历路10号",
          "produceScale": 60,
          "remarks": "一级客户",
          "liaison": {
            "liaisonId": "be0eaf1fdb874461901a76e7a96f73aa",
            "liaisonName": "石龙",
            "phone": "15277054449",
            "master": 1,
            "remark": "111",
            "createtime": "2021-10-26T08:55:58.000+0000",
            "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
            "isdel": 0
          }
        }, {
          "customerId": "0f9e82d884a545079e69261b8173812a",
          "customerName": "广西力源宝科技有限公司",
          "username": "18697985316",
          "province": 1,
          "city": 72,
          "town": 2799,
          "customerAddress": "南宁市洪厉路10号",
          "produceScale": 300,
          "remarks": "",
          "liaison": {
            "liaisonId": "0f9e82d884a545079e69261b81451234",
            "liaisonName": "黎承锋",
            "phone": "18697985316",
            "master": 1,
            "remark": "",
            "createtime": "2021-10-26T08:55:36.000+0000",
            "customerId": "0f9e82d884a545079e69261b8173812a",
            "isdel": 0
          }
        }, {
          "customerId": "7972e6a6fb794eefad56535f244c671b",
          "customerName": "广西力源宝科技有限公司",
          "username": "13006890093",
          "province": 1,
          "city": 2800,
          "town": 2849,
          "customerAddress": "南宁市洪厉路10号",
          "produceScale": 400,
          "remarks": "",
          "liaison": {
            "liaisonId": "eb0f0ad6530141a99277fe2e22143029",
            "liaisonName": "李昌积",
            "phone": "13006890093",
            "master": 1,
            "remark": "",
            "createtime": "2021-10-26T08:55:26.000+0000",
            "customerId": "7972e6a6fb794eefad56535f244c671b",
            "isdel": 0
          }
        }, {
          "customerId": "db2fd9c3fb724717a223262c9ba2bf7a",
          "customerName": "广西力源宝科技有限公司",
          "username": "18172061129",
          "province": 1,
          "city": 72,
          "town": 2799,
          "customerAddress": "南宁市洪厉路10号",
          "produceScale": 600,
          "remarks": "",
          "liaison": {
            "liaisonId": "0f9e82d884a545079e69261b8173912b",
            "liaisonName": "丘志忠",
            "phone": "18978964460",
            "master": 1,
            "remark": null,
            "createtime": "2021-10-26T08:54:10.000+0000",
            "customerId": "db2fd9c3fb724717a223262c9ba2bf7a",
            "isdel": 0
          }
        }, {
          "customerId": "b15a148091c84acca69a2e6dab124f15",
          "customerName": "广西力源宝科技有限公司",
          "username": "18172061697",
          "province": 1,
          "city": 72,
          "town": 2799,
          "customerAddress": "南宁市洪厉路10号",
          "produceScale": 370,
          "remarks": "",
          "liaison": {
            "liaisonId": "7ea0e9b527294d898c81b216cd1397df",
            "liaisonName": "秦小兵",
            "phone": "18172061697",
            "master": 1,
            "remark": "",
            "createtime": "2021-10-26T07:50:34.000+0000",
            "customerId": "b15a148091c84acca69a2e6dab124f15",
            "isdel": 0
          }
        }, {
          "customerId": "5fc745205b3a457cb319552e9a188ec6",
          "customerName": "广西力源宝科技有限公司",
          "username": "18154779839",
          "province": 20,
          "city": 1715,
          "town": 43115,
          "customerAddress": "南宁市洪厉路10号",
          "produceScale": 300,
          "remarks": null,
          "liaison": {
            "liaisonId": "2ec9ee4726b04ece81f196e78d545e47",
            "liaisonName": "韦奇勋",
            "phone": "18154779839",
            "master": 1,
            "remark": "",
            "createtime": "2021-10-26T07:49:58.000+0000",
            "customerId": "5fc745205b3a457cb319552e9a188ec6",
            "isdel": 0
          }
        }],
        "pageNum": 1,
        "pageSize": 13,
        "size": 9,
        "startRow": 1,
        "endRow": 9,
        "pages": 1,
        "prePage": 0,
        "nextPage": 0,
        "isFirstPage": true,
        "isLastPage": true,
        "hasPreviousPage": false,
        "hasNextPage": false,
        "navigatePages": 8,
        "navigatepageNums": [1],
        "navigateFirstPage": 1,
        "navigateLastPage": 1,
        "lastPage": 1,
        "firstPage": 1
      }
    }
  };
});
Mock.mock(/^\/mock_api\/customer\/getCustomerDetail\/\d*/, 'get', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:31:23.437+0000"
    },
    "data": {
      "customerDetail": {
        "customerId": "5fc745205b3a457cb319552e9a188ec6",
        "customerName": "广西力源宝科技有限公司",
        "username": "18154779839",
        "province": 20,
        "city": 1715,
        "town": 43115,
        "customerAddress": "南宁市洪厉路10号",
        "produceScale": 300,
        "remarks": null,
        "liaisonList": [{
          "liaisonId": "2ec9ee4726b04ece81f196e78d545e47",
          "liaisonName": "韦奇勋",
          "phone": "18154779839",
          "master": 1,
          "remark": "",
          "createtime": null,
          "customerId": null,
          "isdel": null
        }]
      }
    }
  };
});
Mock.mock(/^\/mock_api\/regionList\/(\d*)/, 'get', function (data) {
  var regin = /^\/mock_api\/regionList\/(\d*)/.exec(data.url);
  var ret = {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:05:34.427+0000"
    },
    "data": {
      "data": []
    }
  };

  if (regin[1] === '0') {
    ret.data.data.push({
      "id": 20,
      "regionName": "广西",
      "level": 1,
      "parentId": 0,
      "isHot": false
    });
  } else if (regin[1] === '20') {
    ret.data.data.push({
      "id": 1715,
      "regionName": "南宁市",
      "level": 2,
      "parentId": 20,
      "isHot": false
    });
  } else if (regin[1] === '1715') {
    ret.data.data.push({
      "id": 43115,
      "regionName": "江南区",
      "level": 3,
      "parentId": 1715,
      "isHot": false
    });
  }

  return ret;
});
Mock.mock('/mock_api/customer/updateCustomer', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:41:52.897+0000"
    },
    "data": {}
  };
});
Mock.mock(/^\/mock_api\/customer\/(.*)/, 'delete', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:41:52.897+0000"
    },
    "data": {}
  };
});