import "core-js/modules/es.function.name.js";
import Mock from 'mockjs';
import '@/mock/extend';
var user = Mock.mock({
  name: '@ADMIN',
  avatar: '@AVATAR',
  address: '@CITY',
  position: '@POSITION'
});
Mock.mock("/mock_api/account/login?tokenKey=get", 'get', function () {
  var result = {
    "data": {
      "tokenKey": "umoi3bv132geug7y",
      "userKey": "5O2V9Y"
    },
    "meta": {
      "msg": "issued tokenKey success",
      "code": 1000,
      "success": true,
      "timestamp": Date.now()
    }
  };
  return result;
});
Mock.mock("/mock_api/account/login", 'post', function (_ref) {
  var body = _ref.body;
  var result = {
    "meta": {
      "msg": "issue jwt success",
      "code": 1003,
      "success": true,
      "timestamp": "2022-03-24T08:19:02.332+0000"
    },
    "data": {
      "jwt": "eyJhbGciOiJIUzUxMiIsInppcCI6IkRFRiJ9.eNo8jEsKwzAMRO-idQxSpSZ2LlNsooL7SYrthELJ3WND29XwHjPzgVuJMMLgeWDvgsFAaMRaMp57NcIW5RoUhQg6yGuoZT8941wp5lypLHedTda0aWrSFxipF0vonJw60PfrK85MTaTloW3Y8vL7WuP0f94PAAAA__8.pQSpAvquEMHoJdx30vKrZrm8DE7AGNMoLHffM2XQ31_ZFDaBKPNeWo92GD3Vzg8JgUQpZMYrif8lhijJd-cJ9Q",
      "roles": "role_admin",
      "user": {
        "uid": "admin",
        "username": "admin",
        "password": null,
        "salt": null,
        "realName": null,
        "avatar": null,
        "phone": null,
        "email": null,
        "sex": null,
        "status": 1,
        "createTime": "2021-02-23T03:54:21.000+0000",
        "updateTime": null,
        "createWhere": null
      }
    }
  };

  var _JSON$parse = JSON.parse(body),
      appId = _JSON$parse.appId;

  var success = false;

  if (appId === 'admin') {
    success = true;
    result.data.roles = "role_admin";
  } else if (appId === 'test') {
    success = true;
    result.data.roles = "role_customer";
    result.data.user = {
      "uid": "e3350752d0eb4ef391dda92a1c05f830",
      "username": "13000000000",
      "password": null,
      "salt": null,
      "realName": "test",
      "avatar": null,
      "phone": null,
      "email": null,
      "sex": null,
      "status": null,
      "createTime": "2021-06-30T09:47:04.000+0000",
      "updateTime": null,
      "createWhere": null
    };
  } else {
    success = false;
  }

  if (success) {
    result.meta.code = 1003;
    result.meta.success = true;
    result.meta.msg = Mock.mock('@TIMEFIX').CN + '，欢迎回来';
    result.data.jwt = 'Authorization:' + Math.random();
    result.data.timestamp = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  } else {
    result.code = 1002;
    result.meta.success = false;
    result.meta.msg = '账户名错误(admin or test) 密码任意';
  }

  return result;
});
Mock.mock("".concat(process.env.VUE_APP_API_BASE_URL, "/login"), 'post', function (_ref2) {
  var body = _ref2.body;
  var result = {
    data: {}
  };

  var _JSON$parse2 = JSON.parse(body),
      name = _JSON$parse2.name,
      password = _JSON$parse2.password;

  var success = false;

  if (name === 'admin' && password === '888888') {
    success = true;
    result.data.permissions = [{
      id: 'queryForm',
      operation: ['add', 'edit']
    }];
    result.data.roles = [{
      id: 'admin',
      operation: ['add', 'edit', 'delete']
    }];
  } else if (name === 'test' || password === '888888') {
    success = true;
    result.data.permissions = [{
      id: 'queryForm',
      operation: ['add', 'edit']
    }];
    result.data.roles = [{
      id: 'test',
      operation: ['add', 'edit', 'delete']
    }];
  } else {
    success = false;
  }

  if (success) {
    result.code = 0;
    result.message = Mock.mock('@TIMEFIX').CN + '，欢迎回来';
    result.data.user = user;
    result.data.token = 'Authorization:' + Math.random();
    result.data.expireAt = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  } else {
    result.code = -1;
    result.message = '账户名或密码错误（admin/888888 or test/888888）';
  }

  return result;
});