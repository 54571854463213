import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Mock from 'mockjs';
import { activities, templates } from '../common/activityData';
import { teams } from '../../mock/common';
activities.forEach(function (item) {
  item.template = templates[item.activity.type](item.user, item.activity);
});
Mock.mock('/work/activity', 'get', function () {
  return activities;
});
Mock.mock('/work/team', 'get', function () {
  return teams;
});
Mock.mock('/mock_api/device/deviceStatusData', 'get', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T08:45:09.272+0000"
    },
    "data": {
      "data": {
        "stop": Math.floor(Math.random() * 10),
        "runing": Math.floor(Math.random() * 10),
        "error": Math.floor(Math.random() * 10),
        "close": Math.floor(Math.random() * 10)
      }
    }
  };
});
Mock.mock('/mock_api/device/deviceRegion', 'get', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T08:53:51.847+0000"
    },
    "data": {
      "data": [{
        "province": 2,
        "provinceName": "上海",
        "num": 1
      }, {
        "province": 19,
        "provinceName": "广东",
        "num": 2
      }, {
        "province": 20,
        "provinceName": "广西",
        "num": 3
      }, {
        "province": 22,
        "provinceName": "四川",
        "num": 1
      }, {
        "province": 25,
        "provinceName": "云南",
        "num": 1
      }]
    }
  };
});
Mock.mock('/mock_api/error/deviceErrRank', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T08:57:16.098+0000"
    },
    "data": {
      "data": [{
        "errCode": "err315",
        "errContent": "门限位报警",
        "num": 92
      }, {
        "errCode": "err316",
        "errContent": "气压不足报警",
        "num": 89
      }, {
        "errCode": "err088",
        "errContent": "一组投料机械臂接筒超时",
        "num": 79
      }, {
        "errCode": "err082",
        "errContent": "一组搬运机械臂抓筒超时",
        "num": 79
      }, {
        "errCode": "err089",
        "errContent": "一组投料机械臂翻筒超时",
        "num": 68
      }, {
        "errCode": "err104",
        "errContent": "一组前升降托盘寻取超时",
        "num": 66
      }]
    }
  };
});
Mock.mock('/mock_api/customer/produceScale', 'get', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T08:52:54.694+0000"
    },
    "data": {
      "data": {
        "<100": 4,
        "100-200": 0,
        "200-300": 0,
        "300-400": 3,
        ">400": 2
      }
    }
  };
});