import Mock from 'mockjs';
Mock.mock('/mock_api/error/deviceErrorList', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T10:06:17.563+0000"
    },
    "data": {
      "list": {
        "total": 11,
        "list": [{
          "errLogId": "106089553525211136",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 18:02:00",
          "updatetime": "2022-03-20 18:03:14",
          "errContent": "2#垃圾桶提升机故障",
          "status": "0",
          "errCode": "err002"
        }, {
          "errLogId": "106089553562959872",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 18:02:00",
          "updatetime": "2022-03-20 18:03:14",
          "errContent": "一组搬运机械臂抓筒超时",
          "status": "0",
          "errCode": "err082"
        }, {
          "errLogId": "106089553600708608",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 18:02:00",
          "updatetime": "2022-03-20 18:03:14",
          "errContent": "一组投料机械臂接筒超时",
          "status": "0",
          "errCode": "err088"
        }, {
          "errLogId": "106089553646845952",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 18:02:00",
          "updatetime": "2022-03-20 18:03:14",
          "errContent": "一组后升降伺服连接故障",
          "status": "0",
          "errCode": "err071"
        }, {
          "errLogId": "106089553684594688",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 18:02:00",
          "updatetime": "2022-03-20 18:03:14",
          "errContent": "一组急停报警",
          "status": "0",
          "errCode": "err028"
        }, {
          "errLogId": "106089553722343424",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 18:02:00",
          "updatetime": "2022-03-20 18:03:14",
          "errContent": "门限位报警",
          "status": "0",
          "errCode": "err315"
        }, {
          "errLogId": "106083513492570112",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 17:38:00",
          "updatetime": "2022-03-20 17:38:30",
          "errContent": "2#垃圾桶提升机故障",
          "status": "0",
          "errCode": "err002"
        }, {
          "errLogId": "106083513530318848",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 17:38:00",
          "updatetime": "2022-03-20 17:38:30",
          "errContent": "一组搬运机械臂抓筒超时",
          "status": "0",
          "errCode": "err082"
        }, {
          "errLogId": "106083513568067584",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 17:38:00",
          "updatetime": "2022-03-20 17:38:30",
          "errContent": "一组投料机械臂接筒超时",
          "status": "0",
          "errCode": "err088"
        }, {
          "errLogId": "106083513605816320",
          "deviceSn": "device202201001",
          "createtime": "2022-03-20 17:38:00",
          "updatetime": "2022-03-20 17:38:30",
          "errContent": "一组投料机械臂翻筒超时",
          "status": "0",
          "errCode": "err089"
        }],
        "pageNum": 1,
        "pageSize": 10,
        "size": 10,
        "startRow": 1,
        "endRow": 10,
        "pages": 2,
        "prePage": 0,
        "nextPage": 2,
        "isFirstPage": true,
        "isLastPage": false,
        "hasPreviousPage": false,
        "hasNextPage": true,
        "navigatePages": 8,
        "navigatepageNums": [1, 2],
        "navigateFirstPage": 1,
        "navigateLastPage": 2,
        "lastPage": 2,
        "firstPage": 1
      }
    }
  };
});
Mock.mock('/mock_api/device/deviceModelList', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:40:02.636+0000"
    },
    "data": {
      "list": {
        "total": 7,
        "list": [{
          "deviceModelId": "0f9e82d884a545079e69261b81731234",
          "deviceModelName": "厨余垃圾智能处理终端(200kg)",
          "deviceModelType": "CFCJ-DH200",
          "sort": 100,
          "remarks": "日处理200kg",
          "createtime": "2021-03-23T07:28:01.000+0000",
          "isdel": 0
        }, {
          "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
          "deviceModelName": "厨余垃圾智能处理终端(60kg)",
          "deviceModelType": "CFCJ-DH60",
          "sort": 100,
          "remarks": "日处理60kg",
          "createtime": "2021-09-16T03:16:17.000+0000",
          "isdel": 0
        }, {
          "deviceModelId": "1fedf421be5b4316bcf8cc8d53141602",
          "deviceModelName": "厨余垃圾智能处理终端(500kg)",
          "deviceModelType": "CFCJ-DH500",
          "sort": 100,
          "remarks": "日处理500kg",
          "createtime": "2021-10-15T06:08:51.000+0000",
          "isdel": 0
        }, {
          "deviceModelId": "55950516512882688",
          "deviceModelName": "厨余垃圾智能处理终端(1000kg)",
          "deviceModelType": "CFCJ-DH1000",
          "sort": 100,
          "remarks": "日处理1000kg",
          "createtime": "2021-11-02T01:27:22.000+0000",
          "isdel": 0
        }, {
          "deviceModelId": "79490500205215744",
          "deviceModelName": "禽畜粪便处理机",
          "deviceModelType": "3吨",
          "sort": 100,
          "remarks": "",
          "createtime": "2022-01-06T00:26:52.000+0000",
          "isdel": 0
        }, {
          "deviceModelId": "a2c84bb085fe4005a9bb7c7d5c8cd8b5",
          "deviceModelName": "厨余垃圾智能处理终端(10000kg)",
          "deviceModelType": "CFCJ-DH10000",
          "sort": 100,
          "remarks": "日处理10吨",
          "createtime": "2021-06-03T08:36:46.000+0000",
          "isdel": 0
        }, {
          "deviceModelId": "e655d7cab63d44809ddbf5a4ba4cf7c7",
          "deviceModelName": "厨余垃圾智能处理终端(20000)",
          "deviceModelType": "CFCJ-DH20000",
          "sort": 100,
          "remarks": "日处理20t",
          "createtime": "2021-06-03T08:37:22.000+0000",
          "isdel": 0
        }],
        "pageNum": 1,
        "pageSize": 13,
        "size": 7,
        "startRow": 1,
        "endRow": 7,
        "pages": 1,
        "prePage": 0,
        "nextPage": 0,
        "isFirstPage": true,
        "isLastPage": true,
        "hasPreviousPage": false,
        "hasNextPage": false,
        "navigatePages": 8,
        "navigatepageNums": [1],
        "navigateFirstPage": 1,
        "navigateLastPage": 1,
        "lastPage": 1,
        "firstPage": 1
      }
    }
  };
});
Mock.mock('/mock_api/device/deviceModel', 'put', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:41:52.897+0000"
    },
    "data": {}
  };
});
Mock.mock('/mock_api/device/deviceList', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:51:58.307+0000"
    },
    "data": {
      "list": {
        "total": 8,
        "list": [{
          "deviceId": "79488322669379584",
          "deviceSn": "device202201001",
          "address": "广西南宁市江南区南宁市洪历路10号",
          "lon": "108.282653",
          "lat": "22.787978",
          "deviceAddress": "南宁市洪历路10号",
          "province": 20,
          "city": 1715,
          "town": 43115,
          "permissionStatus": 1,
          "dateOut": "2022-01-05",
          "customer": {
            "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
            "customerName": "广西力源宝科技有限公司",
            "country": null,
            "province": 20,
            "city": 1715,
            "town": 43115,
            "customerAddress": "南宁市洪历路10号",
            "produceScale": 60,
            "createtime": "2021-10-26T08:55:58.000+0000",
            "uid": "f6ce436622e8480f8b4c04d2a54476b9",
            "remarks": "一级客户",
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "0f9e82d884a545079e69261b81731234",
            "deviceModelName": "厨余垃圾智能处理终端(200kg)",
            "deviceModelType": "CFCJ-DH200",
            "sort": 100,
            "remarks": "日处理200kg",
            "createtime": "2021-03-23T07:28:01.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "be0eaf1fdb874461901a76e7a96f73aa",
            "liaisonName": "石龙",
            "phone": "15277054449",
            "master": 1,
            "remark": "111",
            "createtime": "2021-10-26T08:55:58.000+0000",
            "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": {
            "deviceSn": "device202201001",
            "devSta": "0",
            "lock": "0",
            "login": "0",
            "dlyIn": "0.00",
            "ttlIn": "1175.00",
            "dlyOut": "0.00",
            "ttlOut": "132.95",
            "imkwh": "0.15",
            "dlykwh": "2.60",
            "ttlkwh": "2223.60",
            "ferTem": "19.34",
            "envTem": "18.60",
            "envHum": "55.20",
            "decEff": "90",
            "dlyRt": "0",
            "createtime": "2022-03-24 17:51:56"
          },
          "imgs": [{
            "picId": "79488322686156800",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/prod/20220106/79488264544714752_sadasdasd.jpg"
          }]
        }, {
          "deviceId": "68730406043648000",
          "deviceSn": "device202009002",
          "address": "四川成都市双流区北坛路110号",
          "lon": "103.93154",
          "lat": "30.58786",
          "deviceAddress": "北坛路110号",
          "province": 22,
          "city": 1930,
          "town": 49324,
          "permissionStatus": 1,
          "dateOut": "2020-09-01",
          "customer": {
            "customerId": "68728037306269696",
            "customerName": "四川嘉博文生物科技有限公司",
            "country": null,
            "province": 22,
            "city": 1930,
            "town": 49324,
            "customerAddress": "成都市双流区滨河路南三段",
            "produceScale": 60,
            "createtime": "2021-12-07T07:40:40.000+0000",
            "uid": "68728037276909568",
            "remarks": null,
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
            "deviceModelName": "厨余垃圾智能处理终端(60kg)",
            "deviceModelType": "CFCJ-DH60",
            "sort": 100,
            "remarks": "日处理60kg",
            "createtime": "2021-09-16T03:16:17.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "68728037314658304",
            "liaisonName": "叶先生",
            "phone": "18030771965",
            "master": 1,
            "remark": "",
            "createtime": "2021-12-07T07:40:41.000+0000",
            "customerId": "68728037306269696",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": null,
          "imgs": [{
            "picId": "68730406060425216",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/prod/20211207/68730155735973888_25184185d47085b3a42e6c2a1df2d64d.jpg"
          }]
        }, {
          "deviceId": "66111297988591616",
          "deviceSn": "device202010001",
          "address": "广东深圳市龙华区广东省深圳市龙华区白石龙路龙光.玖龙玺",
          "lon": "114.056076",
          "lat": "22.704821",
          "deviceAddress": "广东省深圳市龙华区白石龙路龙光.玖龙玺",
          "province": 19,
          "city": 1607,
          "town": 47388,
          "permissionStatus": 1,
          "dateOut": "2020-10-30",
          "customer": {
            "customerId": "66108274230951936",
            "customerName": "深圳市家家分类科技有限公司",
            "country": null,
            "province": 19,
            "city": 1607,
            "town": 47388,
            "customerAddress": "广东省深圳市龙华区白石龙路龙光.玖龙玺家家分类驿站",
            "produceScale": 60,
            "createtime": "2021-11-30T02:10:40.000+0000",
            "uid": "66108274201591808",
            "remarks": null,
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
            "deviceModelName": "厨余垃圾智能处理终端(60kg)",
            "deviceModelType": "CFCJ-DH60",
            "sort": 100,
            "remarks": "日处理60kg",
            "createtime": "2021-09-16T03:16:17.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "66108274243534848",
            "liaisonName": "郭站长",
            "phone": "176 0300 4322",
            "master": 1,
            "remark": "",
            "createtime": "2021-11-30T02:10:40.000+0000",
            "customerId": "66108274230951936",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": {
            "deviceSn": "device202010001",
            "devSta": "0",
            "lock": "0",
            "login": "0",
            "dlyIn": "0.00",
            "ttlIn": "2040.00",
            "dlyOut": "0.00",
            "ttlOut": "231.70",
            "imkwh": "0.24",
            "dlykwh": "4.20",
            "ttlkwh": "6211.60",
            "ferTem": "22.80",
            "envTem": "42.10",
            "envHum": "23.31",
            "decEff": "90",
            "dlyRt": "0",
            "createtime": "2022-03-24 17:51:50"
          },
          "imgs": [{
            "picId": "66896517423693824",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/prod/20211202/66896508246556672_sadasdasd.jpg"
          }]
        }, {
          "deviceId": "64649165933641728",
          "deviceSn": "device202112001",
          "address": "广西钦州市钦南区钦州市钦南区锦绣大道与中马南三街交汇处",
          "lon": "108.636379",
          "lat": "21.944363",
          "deviceAddress": "钦州市钦南区锦绣大道与中马南三街交汇处",
          "province": 20,
          "city": 1753,
          "town": 25189,
          "permissionStatus": 1,
          "dateOut": "2021-11-30",
          "customer": {
            "customerId": "64634772042809344",
            "customerName": "钦州港市政管理有限公司",
            "country": null,
            "province": 20,
            "city": 1753,
            "town": 25189,
            "customerAddress": "钦州市钦南区锦绣大道",
            "produceScale": 60,
            "createtime": "2021-12-03T01:34:19.000+0000",
            "uid": "64634772009254912",
            "remarks": null,
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
            "deviceModelName": "厨余垃圾智能处理终端(60kg)",
            "deviceModelType": "CFCJ-DH60",
            "sort": 100,
            "remarks": "日处理60kg",
            "createtime": "2021-09-16T03:16:17.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "64634772055392256",
            "liaisonName": "张先生",
            "phone": "18322456698",
            "master": 1,
            "remark": "",
            "createtime": "2021-12-03T01:34:20.000+0000",
            "customerId": "64634772042809344",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": null,
          "imgs": [{
            "picId": "67164337952784384",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/prod/20211203/67164322102509568_6c0bfb18d4ebfade8d18bd0a9c7a3f97.jpg"
          }]
        }, {
          "deviceId": "c542c6c5a29a487c8ff41a016e2cda00",
          "deviceSn": "device202005001",
          "address": "云南普洱市思茅区普洱工业园区",
          "lon": "100.913398",
          "lat": "22.789801",
          "deviceAddress": "普洱工业园区",
          "province": 25,
          "city": 2281,
          "town": 2282,
          "permissionStatus": 1,
          "dateOut": "2020-05-15",
          "customer": {
            "customerId": "0f9e82d884a545079e69261b8173812a",
            "customerName": "广西力源宝科技有限公司",
            "country": null,
            "province": 1,
            "city": 72,
            "town": 2799,
            "customerAddress": "南宁市洪厉路10号",
            "produceScale": 300,
            "createtime": "2021-10-26T08:55:36.000+0000",
            "uid": "091581d52aab4d9487ab8b768d6581d8",
            "remarks": "",
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
            "deviceModelName": "厨余垃圾智能处理终端(60kg)",
            "deviceModelType": "CFCJ-DH60",
            "sort": 100,
            "remarks": "日处理60kg",
            "createtime": "2021-09-16T03:16:17.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "0f9e82d884a545079e69261b81451234",
            "liaisonName": "黎承锋",
            "phone": "18697985316",
            "master": 1,
            "remark": "",
            "createtime": "2021-10-26T08:55:36.000+0000",
            "customerId": "0f9e82d884a545079e69261b8173812a",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": null,
          "imgs": [{
            "picId": "6648e647609945a0a764133c630068b2",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/test/20211026/52fdcbce0dca4ccf8b6c9d625d76a279_厨余垃圾设备.jpg"
          }]
        }, {
          "deviceId": "132eadb95f224564838c031612652e58",
          "deviceSn": "device202009001",
          "address": "广东深圳市南山区南山麻雀岭工业区",
          "lon": "113.946776",
          "lat": "22.546964",
          "deviceAddress": "南山麻雀岭工业区",
          "province": 19,
          "city": 1607,
          "town": 3155,
          "permissionStatus": 1,
          "dateOut": "2020-07-31",
          "customer": {
            "customerId": "66108274230951936",
            "customerName": "深圳市家家分类科技有限公司",
            "country": null,
            "province": 19,
            "city": 1607,
            "town": 47388,
            "customerAddress": "广东省深圳市龙华区白石龙路龙光.玖龙玺家家分类驿站",
            "produceScale": 60,
            "createtime": "2021-11-30T02:10:40.000+0000",
            "uid": "66108274201591808",
            "remarks": null,
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "a2c84bb085fe4005a9bb7c7d5c8cd8b5",
            "deviceModelName": "厨余垃圾智能处理终端(10000kg)",
            "deviceModelType": "CFCJ-DH10000",
            "sort": 100,
            "remarks": "日处理10吨",
            "createtime": "2021-06-03T08:36:46.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "66108274243534848",
            "liaisonName": "郭站长",
            "phone": "176 0300 4322",
            "master": 1,
            "remark": "",
            "createtime": "2021-11-30T02:10:40.000+0000",
            "customerId": "66108274230951936",
            "isdel": 0
          },
          "deviceErrs": [{
            "deviceSn": "device202009001",
            "errCode": "err026",
            "errContent": "前端急停报警",
            "createTime": "2022-03-10 15:16:29"
          }, {
            "deviceSn": "device202009001",
            "errCode": "err001",
            "errContent": "1#垃圾桶提升机故障",
            "createTime": "2022-03-10 15:16:29"
          }, {
            "deviceSn": "device202009001",
            "errCode": "err002",
            "errContent": "2#垃圾桶提升机故障",
            "createTime": "2022-03-10 15:16:29"
          }],
          "deviceInfos": null,
          "imgs": [{
            "picId": "81d89ba2a43f4e069949a2daebc099e0",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/test/20211026/46deedf295ae423b99ddd92342c41662_厨余垃圾设备.jpg"
          }]
        }, {
          "deviceId": "1a566fa3dab148c4a5871c126200cea6",
          "deviceSn": "device202008001",
          "address": "上海宝山区罗店镇上海市宝山区罗店医院",
          "lon": "121.35718",
          "lat": "31.419326",
          "deviceAddress": "上海市宝山区罗店医院",
          "province": 2,
          "city": 2824,
          "town": 51911,
          "permissionStatus": 1,
          "dateOut": "2020-08-13",
          "customer": {
            "customerId": "b15a148091c84acca69a2e6dab124f15",
            "customerName": "广西力源宝科技有限公司",
            "country": null,
            "province": 1,
            "city": 72,
            "town": 2799,
            "customerAddress": "南宁市洪厉路10号",
            "produceScale": 370,
            "createtime": "2021-10-26T07:50:34.000+0000",
            "uid": "33282ff695514d44a0acf4d48ff4be4e",
            "remarks": "",
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
            "deviceModelName": "厨余垃圾智能处理终端(60kg)",
            "deviceModelType": "CFCJ-DH60",
            "sort": 100,
            "remarks": "日处理60kg",
            "createtime": "2021-09-16T03:16:17.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "7ea0e9b527294d898c81b216cd1397df",
            "liaisonName": "秦小兵",
            "phone": "18172061697",
            "master": 1,
            "remark": "",
            "createtime": "2021-10-26T07:50:34.000+0000",
            "customerId": "b15a148091c84acca69a2e6dab124f15",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": null,
          "imgs": [{
            "picId": "8d1e797c3fd145e49fcbd6b515ceabae",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/test/20211026/7f959583a69847328294bc638fcd07ca_厨余垃圾设备.jpg"
          }]
        }, {
          "deviceId": "c198b7251d8c4d2ba40fb1678b45f914",
          "deviceSn": "device202006001",
          "address": "广西南宁市江南区南宁市洪历路10号",
          "lon": "108.255307",
          "lat": "22.770107",
          "deviceAddress": "南宁市洪历路10号",
          "province": 20,
          "city": 1715,
          "town": 43115,
          "permissionStatus": 1,
          "dateOut": "2020-06-10",
          "customer": {
            "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
            "customerName": "广西力源宝科技有限公司",
            "country": null,
            "province": 20,
            "city": 1715,
            "town": 43115,
            "customerAddress": "南宁市洪历路10号",
            "produceScale": 60,
            "createtime": "2021-10-26T08:55:58.000+0000",
            "uid": "f6ce436622e8480f8b4c04d2a54476b9",
            "remarks": "一级客户",
            "isdel": 0
          },
          "deviceModel": {
            "deviceModelId": "1cd08741ea5c4acbbf687f3e66f91418",
            "deviceModelName": "厨余垃圾智能处理终端(60kg)",
            "deviceModelType": "CFCJ-DH60",
            "sort": 100,
            "remarks": "日处理60kg",
            "createtime": "2021-09-16T03:16:17.000+0000",
            "isdel": 0
          },
          "liaison": {
            "liaisonId": "be0eaf1fdb874461901a76e7a96f73aa",
            "liaisonName": "石龙",
            "phone": "15277054449",
            "master": 1,
            "remark": "111",
            "createtime": "2021-10-26T08:55:58.000+0000",
            "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
            "isdel": 0
          },
          "deviceErrs": [],
          "deviceInfos": {
            "deviceSn": "device202006001",
            "devSta": "0",
            "lock": "0",
            "login": "0",
            "dlyIn": "0.00",
            "ttlIn": "2420.00",
            "dlyOut": "0.00",
            "ttlOut": "225.05",
            "imkwh": "0.23",
            "dlykwh": "4.80",
            "ttlkwh": "8610.40",
            "ferTem": "18.52",
            "envTem": "18.80",
            "envHum": "66.85",
            "decEff": "90",
            "dlyRt": "7",
            "createtime": "2022-03-24 17:51:57"
          },
          "imgs": [{
            "picId": "56670999264886784",
            "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/test/20211104/56670952515174400_厨余垃圾设备.jpg"
          }]
        }],
        "pageNum": 1,
        "pageSize": 13,
        "size": 0,
        "startRow": 0,
        "endRow": 0,
        "pages": 1,
        "prePage": 0,
        "nextPage": 0,
        "isFirstPage": false,
        "isLastPage": false,
        "hasPreviousPage": false,
        "hasNextPage": false,
        "navigatePages": 0,
        "navigatepageNums": null,
        "navigateFirstPage": 0,
        "navigateLastPage": 0,
        "lastPage": 0,
        "firstPage": 0
      }
    }
  };
});
Mock.mock(/^\/mock_api\/device\/\d*/, 'get', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:54:15.753+0000"
    },
    "data": {
      "device": {
        "deviceId": "79488322669379584",
        "deviceSn": "device202201001",
        "address": "广西南宁市江南区南宁市洪历路10号",
        "lon": "108.282653",
        "lat": "22.787978",
        "permissionStatus": 1,
        "dateOut": "2022-01-05",
        "dateInstall": "2022-01-06",
        "dateAccept": "2022-01-06",
        "dateQuality": "2024-01-06",
        "imgs": [{
          "picId": "79488322686156800",
          "path": "http://lybdevice.oss-cn-shenzhen.aliyuncs.com/prod/20220106/79488264544714752_sadasdasd.jpg"
        }],
        "liaison": {
          "liaisonId": "be0eaf1fdb874461901a76e7a96f73aa",
          "liaisonName": "石龙",
          "phone": "15277054449",
          "master": 1,
          "remark": "111",
          "createtime": "2021-10-26T08:55:58.000+0000",
          "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
          "isdel": 0
        },
        "customer": {
          "customerId": "fe5fe6dabff54e8e8131f3a0b490c9eb",
          "customerName": "广西力源宝科技有限公司",
          "country": null,
          "province": 20,
          "city": 1715,
          "town": 43115,
          "customerAddress": "南宁市洪历路10号",
          "produceScale": 60,
          "createtime": "2021-10-26T08:55:58.000+0000",
          "uid": "f6ce436622e8480f8b4c04d2a54476b9",
          "remarks": "一级客户",
          "isdel": 0
        },
        "deviceModel": {
          "deviceModelId": "0f9e82d884a545079e69261b81731234",
          "deviceModelName": "厨余垃圾智能处理终端(200kg)",
          "deviceModelType": "CFCJ-DH200",
          "sort": 100,
          "remarks": "日处理200kg",
          "createtime": "2021-03-23T07:28:01.000+0000",
          "isdel": 0
        },
        "deviceInfos": {
          "deviceSn": "device202201001",
          "devSta": "0",
          "lock": "0",
          "login": "0",
          "dlyIn": "0.00",
          "ttlIn": "1175.00",
          "dlyOut": "0.00",
          "ttlOut": "132.95",
          "imkwh": "0.15",
          "dlykwh": "2.60",
          "ttlkwh": "2223.60",
          "ferTem": "19.13",
          "envTem": "18.60",
          "envHum": "55.12",
          "decEff": "90",
          "dlyRt": "0",
          "createtime": null
        },
        "deviceErrs": null
      }
    }
  };
});
Mock.mock('/mock_api/device/deviceDayData', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:56:46.377+0000"
    },
    "data": {
      "data": {
        "out": 139,
        "in": 57,
        "kwh": 24.8,
        "runtime": 627
      }
    }
  };
});
Mock.mock('/mock_api/device/getDeviceInfos', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:56:46.390+0000"
    },
    "data": {
      "list": [{
        "id": "623c3d7051555f473792ba30",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:44:16.804"
      }, {
        "id": "623c3d7a51555f473792ba33",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:44:26.815"
      }, {
        "id": "623c3d8451555f473792ba36",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:44:36.855"
      }, {
        "id": "623c3d8e51555f473792ba39",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:44:46.814"
      }, {
        "id": "623c3d9851555f473792ba3c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:44:56.819"
      }, {
        "id": "623c3da251555f473792ba3f",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:45:06.804"
      }, {
        "id": "623c3dac51555f473792ba42",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:45:16.819"
      }, {
        "id": "623c3db651555f473792ba45",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:45:26.809"
      }, {
        "id": "623c3dc051555f473792ba48",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:45:36.849"
      }, {
        "id": "623c3dca51555f473792ba4b",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.16",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:45:46.82"
      }, {
        "id": "623c3dd451555f473792ba4e",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:45:56.819"
      }, {
        "id": "623c3dde51555f473792ba51",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:46:06.804"
      }, {
        "id": "623c3de851555f473792ba54",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:46:16.819"
      }, {
        "id": "623c3df251555f473792ba57",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:46:26.809"
      }, {
        "id": "623c3dfc51555f473792ba5a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:46:36.839"
      }, {
        "id": "623c3e0651555f473792ba5d",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:46:46.804"
      }, {
        "id": "623c3e1051555f473792ba60",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:46:56.819"
      }, {
        "id": "623c3e1a51555f473792ba63",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:47:06.805"
      }, {
        "id": "623c3e2451555f473792ba66",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:47:16.819"
      }, {
        "id": "623c3e2e51555f473792ba69",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:47:26.809"
      }, {
        "id": "623c3e3851555f473792ba6c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:47:36.824"
      }, {
        "id": "623c3e4251555f473792ba6f",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.04",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:47:46.814"
      }, {
        "id": "623c3e4c51555f473792ba72",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:47:56.814"
      }, {
        "id": "623c3e5651555f473792ba75",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:48:06.8"
      }, {
        "id": "623c3e6051555f473792ba78",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:48:16.814"
      }, {
        "id": "623c3e6a51555f473792ba7b",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:48:26.825"
      }, {
        "id": "623c3e7451555f473792ba7e",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:48:36.819"
      }, {
        "id": "623c3e7e51555f473792ba81",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:48:46.805"
      }, {
        "id": "623c3e8851555f473792ba84",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:48:56.819"
      }, {
        "id": "623c3e9251555f473792ba87",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:49:06.814"
      }, {
        "id": "623c3e9c51555f473792ba8a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:49:16.819"
      }, {
        "id": "623c3ea651555f473792ba8d",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:49:26.814"
      }, {
        "id": "623c3eb051555f473792ba90",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:49:36.815"
      }, {
        "id": "623c3eba51555f473792ba93",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:49:46.815"
      }, {
        "id": "623c3ec451555f473792ba96",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:49:56.824"
      }, {
        "id": "623c3ece51555f473792ba99",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:50:06.824"
      }, {
        "id": "623c3ed851555f473792ba9c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:50:16.814"
      }, {
        "id": "623c3ee251555f473792ba9f",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.70",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:50:26.844"
      }, {
        "id": "623c3eec51555f473792baa2",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:50:36.824"
      }, {
        "id": "623c3ef651555f473792baa5",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:50:46.839"
      }, {
        "id": "623c3f0051555f473792baa8",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.70",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:50:56.82"
      }, {
        "id": "623c3f0a51555f473792baab",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:51:06.824"
      }, {
        "id": "623c3f1451555f473792baae",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:51:16.809"
      }, {
        "id": "623c3f1e51555f473792bab1",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:51:26.814"
      }, {
        "id": "623c3f2851555f473792bab4",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:51:36.814"
      }, {
        "id": "623c3f3251555f473792bab7",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:51:46.815"
      }, {
        "id": "623c3f3c51555f473792baba",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:51:56.825"
      }, {
        "id": "623c3f4651555f473792babd",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:52:06.824"
      }, {
        "id": "623c3f5051555f473792bac0",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:52:16.879"
      }, {
        "id": "623c3f5b51555f473792bac3",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:52:27.104"
      }, {
        "id": "623c3f6451555f473792bac6",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:52:36.824"
      }, {
        "id": "623c3f6e51555f473792bac9",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:52:46.815"
      }, {
        "id": "623c3f7851555f473792bacc",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:52:56.849"
      }, {
        "id": "623c3f8251555f473792bacf",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:53:06.82"
      }, {
        "id": "623c3f8c51555f473792bad2",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:53:16.85"
      }, {
        "id": "623c3f9651555f473792bad5",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:53:26.824"
      }, {
        "id": "623c3fa051555f473792bad8",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:53:36.815"
      }, {
        "id": "623c3faa51555f473792badb",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:53:46.825"
      }, {
        "id": "623c3fb451555f473792bade",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:53:56.839"
      }, {
        "id": "623c3fbe51555f473792bae1",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.13",
        "envTem": "18.60",
        "envHum": "55.12",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:54:06.85"
      }]
    }
  };
});
Mock.mock('/mock_api/device/getDeviceInfosByDate', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:56:50.422+0000"
    },
    "data": {
      "list": [{
        "id": "623305e351555f47378ffb85",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2198.80",
        "ferTem": "27.48",
        "envTem": "27.50",
        "envHum": "49.37",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-17T17:56:51.187"
      }, {
        "id": "62332ded51555f473790075c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.00",
        "ttlkwh": "2199.20",
        "ferTem": "27.88",
        "envTem": "26.80",
        "envHum": "53.86",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-17T20:47:41.467"
      }, {
        "id": "623355f751555f473790134d",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.40",
        "ttlkwh": "2199.60",
        "ferTem": "28.09",
        "envTem": "26.10",
        "envHum": "56.22",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-17T23:38:31.837"
      }, {
        "id": "62337e0251555f4737901f4a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.40",
        "ttlkwh": "2200.00",
        "ferTem": "28.29",
        "envTem": "25.50",
        "envHum": "57.80",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T02:29:22.236"
      }, {
        "id": "6233a60c51555f4737902b45",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.80",
        "ttlkwh": "2200.40",
        "ferTem": "28.29",
        "envTem": "25.00",
        "envHum": "58.66",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T05:20:12.571"
      }, {
        "id": "6233ce1751555f473790372f",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.40",
        "ttlkwh": "2201.00",
        "ferTem": "28.09",
        "envTem": "24.50",
        "envHum": "59.13",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T08:11:03"
      }, {
        "id": "6233f62151555f4737904301",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.80",
        "ttlkwh": "2201.40",
        "ferTem": "28.09",
        "envTem": "25.20",
        "envHum": "57.48",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T11:01:53.464"
      }, {
        "id": "62341e2b51555f4737904ee2",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.20",
        "ttlkwh": "2201.80",
        "ferTem": "27.88",
        "envTem": "26.20",
        "envHum": "53.39",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T13:52:43.73"
      }, {
        "id": "6234463651555f4737905ab9",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.12",
        "dlykwh": "2.60",
        "ttlkwh": "2202.20",
        "ferTem": "27.88",
        "envTem": "27.40",
        "envHum": "51.50",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T16:43:34.17"
      }, {
        "id": "62346e4051555f473790665f",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.00",
        "ttlkwh": "2202.60",
        "ferTem": "28.09",
        "envTem": "27.20",
        "envHum": "53.15",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T19:34:24.49"
      }, {
        "id": "6234964a51555f4737907240",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.40",
        "ttlkwh": "2203.00",
        "ferTem": "28.29",
        "envTem": "26.10",
        "envHum": "56.77",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-18T22:25:14.879"
      }, {
        "id": "6234be5f51555f4737907e2c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.20",
        "ttlkwh": "2203.40",
        "ferTem": "28.50",
        "envTem": "25.60",
        "envHum": "57.95",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T01:16:15.199"
      }, {
        "id": "6234e66951555f4737908a29",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.60",
        "ttlkwh": "2203.80",
        "ferTem": "28.50",
        "envTem": "25.10",
        "envHum": "58.58",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T04:07:05.569"
      }, {
        "id": "62350e7351555f4737909625",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.00",
        "ttlkwh": "2204.20",
        "ferTem": "28.29",
        "envTem": "24.90",
        "envHum": "58.82",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T06:57:55.939"
      }, {
        "id": "6235367e51555f473790a215",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.40",
        "ttlkwh": "2204.60",
        "ferTem": "28.09",
        "envTem": "25.30",
        "envHum": "57.72",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T09:48:46.32"
      }, {
        "id": "62355e8851555f473790adf6",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.80",
        "ttlkwh": "2205.00",
        "ferTem": "27.88",
        "envTem": "27.60",
        "envHum": "52.05",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T12:39:36.819"
      }, {
        "id": "6235869351555f473790b9e0",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.40",
        "ttlkwh": "2205.60",
        "ferTem": "28.29",
        "envTem": "28.20",
        "envHum": "51.10",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T15:30:27.13"
      }, {
        "id": "6235ae9d51555f473790c5b0",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.80",
        "ttlkwh": "2206.00",
        "ferTem": "28.70",
        "envTem": "27.70",
        "envHum": "53.31",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T18:21:17.565"
      }, {
        "id": "6235d6a751555f473790d199",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.20",
        "ttlkwh": "2206.40",
        "ferTem": "29.11",
        "envTem": "26.60",
        "envHum": "56.93",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-19T21:12:07.844"
      }, {
        "id": "6235feb251555f473790dd9a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.00",
        "ttlkwh": "2206.80",
        "ferTem": "29.31",
        "envTem": "26.00",
        "envHum": "57.95",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T00:02:58.204"
      }, {
        "id": "623626bc51555f473790e99a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.40",
        "ttlkwh": "2207.20",
        "ferTem": "29.11",
        "envTem": "25.70",
        "envHum": "58.27",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T02:53:48.584"
      }, {
        "id": "62364ec651555f473790f59c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.80",
        "ttlkwh": "2207.60",
        "ferTem": "29.11",
        "envTem": "25.40",
        "envHum": "58.43",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T05:44:38.959"
      }, {
        "id": "623676d151555f4737910195",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.20",
        "ttlkwh": "2208.00",
        "ferTem": "28.50",
        "envTem": "25.10",
        "envHum": "58.66",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T08:35:29.355"
      }, {
        "id": "62369edb51555f4737910d6e",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.60",
        "ttlkwh": "2208.40",
        "ferTem": "28.29",
        "envTem": "26.30",
        "envHum": "55.98",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T11:26:19.719"
      }, {
        "id": "6236c6e651555f473791195a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.00",
        "ttlkwh": "2208.80",
        "ferTem": "28.29",
        "envTem": "27.20",
        "envHum": "54.72",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T14:17:10.105"
      }, {
        "id": "6236eef051555f473791253a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.40",
        "ttlkwh": "2209.20",
        "ferTem": "28.70",
        "envTem": "27.50",
        "envHum": "54.02",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T17:08:00.519"
      }, {
        "id": "623716fe51555f4737913119",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.00",
        "ttlkwh": "2209.80",
        "ferTem": "29.11",
        "envTem": "27.10",
        "envHum": "56.38",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T19:58:54.61"
      }, {
        "id": "62373f0951555f4737913d03",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.40",
        "ttlkwh": "2210.20",
        "ferTem": "29.31",
        "envTem": "26.60",
        "envHum": "57.80",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-20T22:49:45.019"
      }, {
        "id": "6237671351555f47379148f4",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.20",
        "ttlkwh": "2210.60",
        "ferTem": "29.51",
        "envTem": "26.30",
        "envHum": "58.35",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T01:40:35.38"
      }, {
        "id": "62378f1d51555f47379154e6",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.60",
        "ttlkwh": "2211.00",
        "ferTem": "29.31",
        "envTem": "26.00",
        "envHum": "58.82",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T04:31:25.75"
      }, {
        "id": "6237b72851555f47379160d9",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.00",
        "ttlkwh": "2211.40",
        "ferTem": "29.31",
        "envTem": "25.80",
        "envHum": "59.21",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T07:22:16.124"
      }, {
        "id": "6237df3251555f4737916cb0",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.40",
        "ttlkwh": "2211.80",
        "ferTem": "29.31",
        "envTem": "26.70",
        "envHum": "57.87",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T10:13:06.565"
      }, {
        "id": "6238073c51555f4737917883",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.00",
        "ttlkwh": "2212.40",
        "ferTem": "29.31",
        "envTem": "27.90",
        "envHum": "54.25",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T13:03:56.89"
      }, {
        "id": "62382f4751555f4737918463",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.40",
        "ttlkwh": "2212.80",
        "ferTem": "29.51",
        "envTem": "29.20",
        "envHum": "51.73",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T15:54:47.344"
      }, {
        "id": "6238575151555f473791904e",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.80",
        "ttlkwh": "2213.20",
        "ferTem": "30.12",
        "envTem": "29.10",
        "envHum": "53.23",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T18:45:37.745"
      }, {
        "id": "62387f5c51555f4737919c3b",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.20",
        "ttlkwh": "2213.60",
        "ferTem": "30.33",
        "envTem": "27.80",
        "envHum": "57.24",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-21T21:36:28.035"
      }, {
        "id": "6238a76651555f473791a831",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.00",
        "ttlkwh": "2214.00",
        "ferTem": "30.53",
        "envTem": "27.30",
        "envHum": "58.74",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T00:27:18.414"
      }, {
        "id": "6238cf7051555f473791b433",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.40",
        "ttlkwh": "2214.40",
        "ferTem": "30.73",
        "envTem": "26.80",
        "envHum": "59.29",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T03:18:08.79"
      }, {
        "id": "6238f77b51555f473791c031",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.80",
        "ttlkwh": "2214.80",
        "ferTem": "30.33",
        "envTem": "26.50",
        "envHum": "59.61",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T06:08:59.164"
      }, {
        "id": "62391f8551555f473791cc29",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.20",
        "ttlkwh": "2215.20",
        "ferTem": "30.33",
        "envTem": "26.50",
        "envHum": "59.29",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T08:59:49.544"
      }, {
        "id": "6239479951555f473791d823",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.60",
        "ttlkwh": "2215.60",
        "ferTem": "30.12",
        "envTem": "27.30",
        "envHum": "57.64",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T11:50:49.96"
      }, {
        "id": "62396fa451555f473791e40a",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.16",
        "dlykwh": "2.00",
        "ttlkwh": "2216.00",
        "ferTem": "29.92",
        "envTem": "27.00",
        "envHum": "56.93",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T14:41:40.325"
      }, {
        "id": "623997ae51555f473791eff5",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2216.60",
        "ferTem": "29.72",
        "envTem": "25.70",
        "envHum": "57.48",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T17:32:30.724"
      }, {
        "id": "6239bfb951555f473791fbe5",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.00",
        "ttlkwh": "2217.00",
        "ferTem": "29.11",
        "envTem": "24.10",
        "envHum": "57.64",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T20:23:21.074"
      }, {
        "id": "6239e7c351555f47379207d9",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.40",
        "ttlkwh": "2217.40",
        "ferTem": "28.09",
        "envTem": "22.70",
        "envHum": "57.80",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-22T23:14:11.44"
      }, {
        "id": "623a0fcd51555f47379213c8",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.40",
        "ttlkwh": "2217.80",
        "ferTem": "27.07",
        "envTem": "21.40",
        "envHum": "57.24",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T02:05:01.794"
      }, {
        "id": "623a37d851555f4737921fc2",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.80",
        "ttlkwh": "2218.20",
        "ferTem": "25.85",
        "envTem": "20.20",
        "envHum": "56.85",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T04:55:52.169"
      }, {
        "id": "623a5fe251555f4737922bba",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.20",
        "ttlkwh": "2218.60",
        "ferTem": "24.63",
        "envTem": "19.20",
        "envHum": "56.93",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T07:46:42.519"
      }, {
        "id": "623a87ec51555f473792379e",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.60",
        "ttlkwh": "2219.00",
        "ferTem": "23.61",
        "envTem": "18.90",
        "envHum": "56.38",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T10:37:32.879"
      }, {
        "id": "623aaff751555f4737924394",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "2.00",
        "ttlkwh": "2219.40",
        "ferTem": "22.19",
        "envTem": "18.80",
        "envHum": "55.83",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T13:28:23.254"
      }, {
        "id": "623ad80151555f4737924f86",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.40",
        "ttlkwh": "2219.80",
        "ferTem": "21.58",
        "envTem": "18.80",
        "envHum": "55.28",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T16:19:13.589"
      }, {
        "id": "623b000b51555f4737925b75",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.80",
        "ttlkwh": "2220.20",
        "ferTem": "21.17",
        "envTem": "18.50",
        "envHum": "55.59",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T19:10:03.959"
      }, {
        "id": "623b281651555f4737926760",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "3.40",
        "ttlkwh": "2220.80",
        "ferTem": "20.76",
        "envTem": "18.20",
        "envHum": "55.83",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-23T22:00:54.335"
      }, {
        "id": "623b502051555f473792735e",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.20",
        "ttlkwh": "2221.20",
        "ferTem": "20.56",
        "envTem": "17.80",
        "envHum": "56.06",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T00:51:44.685"
      }, {
        "id": "623b782b51555f4737927f5c",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "0.60",
        "ttlkwh": "2221.60",
        "ferTem": "20.15",
        "envTem": "17.70",
        "envHum": "55.98",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T03:42:35.025"
      }, {
        "id": "623ba03551555f4737928b59",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.00",
        "ttlkwh": "2222.00",
        "ferTem": "19.95",
        "envTem": "17.50",
        "envHum": "56.06",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T06:33:25.394"
      }, {
        "id": "623bc83f51555f4737929756",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.14",
        "dlykwh": "1.40",
        "ttlkwh": "2222.40",
        "ferTem": "19.54",
        "envTem": "17.40",
        "envHum": "55.83",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T09:24:15.755"
      }, {
        "id": "623bf04a51555f473792a334",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "1.80",
        "ttlkwh": "2222.80",
        "ferTem": "19.34",
        "envTem": "17.70",
        "envHum": "55.28",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T12:15:06.114"
      }, {
        "id": "623c185451555f473792af1d",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.20",
        "ttlkwh": "2223.20",
        "ferTem": "19.34",
        "envTem": "18.30",
        "envHum": "54.72",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T15:05:56.63"
      }, {
        "id": "623c405e51555f473792bb11",
        "deviceSn": "device202201001",
        "devSta": "0",
        "lock": "0",
        "login": "0",
        "dlyIn": "0.00",
        "ttlIn": "1175.00",
        "dlyOut": "0.00",
        "ttlOut": "132.95",
        "imkwh": "0.15",
        "dlykwh": "2.60",
        "ttlkwh": "2223.60",
        "ferTem": "19.34",
        "envTem": "18.60",
        "envHum": "55.20",
        "decEff": "90",
        "dlyRt": "0",
        "createtime": "2022-03-24T17:56:46.82"
      }]
    }
  };
});
Mock.mock('/mock_api/device/deviceInfoDayList', 'post', function () {
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:56:46.327+0000"
    },
    "data": {
      "list": {
        "total": 8,
        "list": [{
          "dlyOut": 1,
          "dlyIn": 2,
          "dn": 2.6,
          "date": "2022-03-24"
        }, {
          "dlyOut": 6,
          "dlyIn": 9,
          "dn": 3.6,
          "date": "2022-03-23"
        }, {
          "dlyOut": 8,
          "dlyIn": 15,
          "dn": 3.4,
          "date": "2022-03-22"
        }, {
          "dlyOut": 2,
          "dlyIn": 3,
          "dn": 3.6,
          "date": "2022-03-21"
        }, {
          "dlyOut": 6,
          "dlyIn": 8,
          "dn": 3.6,
          "date": "2022-03-20"
        }, {
          "dlyOut": 3,
          "dlyIn": 7,
          "dn": 3.6,
          "date": "2022-03-19"
        }, {
          "dlyOut": 4,
          "dlyIn": 8,
          "dn": 3.6,
          "date": "2022-03-18"
        }, {
          "dlyOut": 5,
          "dlyIn": 10,
          "dn": 3.4,
          "date": "2022-03-17"
        }],
        "pageNum": 1,
        "pageSize": 30,
        "size": 8,
        "startRow": 0,
        "endRow": 7,
        "pages": 1,
        "prePage": 0,
        "nextPage": 0,
        "isFirstPage": true,
        "isLastPage": true,
        "hasPreviousPage": false,
        "hasNextPage": false,
        "navigatePages": 8,
        "navigatepageNums": [1],
        "navigateFirstPage": 1,
        "navigateLastPage": 1,
        "lastPage": 1,
        "firstPage": 1
      }
    }
  };
});
Mock.mock('/mock_api/device/deviceList111', 'post', function () {
  return {};
});