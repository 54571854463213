import Mock from 'mockjs';
import '@/mock/extend';
var projectArr = Mock.mock({
  'list|6': [{
    logo: '@LOGO',
    desc: '@SAYING'
  }]
}).list;
Mock.mock('/project', 'get', function () {
  return projectArr;
});