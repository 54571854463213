import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { users, groups } from './index';
var events = [{
  type: 0,
  event: '八月迭代'
}, {
  type: 1,
  event: '留言'
}, {
  type: 2,
  event: '项目进展'
}];
var activities = users.map(function (user, index) {
  return {
    user: Object.assign({}, user, {
      group: groups[user.groupId]
    }),
    activity: events[index % events.length],
    template: ''
  };
});
var templates = [function (user, activity) {
  return "".concat(user.name, " \u5728 <a >").concat(user.group, "</a> \u65B0\u5EFA\u9879\u76EE <a>").concat(activity.event, "</a>");
}, function (user, activity) {
  return "".concat(user.name, " \u5728 <a >").concat(user.group, "</a> \u53D1\u5E03\u4E86 <a>").concat(activity.event, "</a>");
}, function (user, activity) {
  return "".concat(user.name, " \u5C06 <a >").concat(activity.event, "</a> \u66F4\u65B0\u81F3\u5DF2\u53D1\u5E03\u72B6\u6001");
}];
export { activities, templates };