import "core-js/modules/es.array.map.js";
import Mock from 'mockjs';
Mock.mock('/mock_api/errCode/GetErrCodeList', 'post', function () {
  var length = Math.floor(Math.random() * 30 + 1);
  var ar = Mock.mock("@range(".concat(length, ")"));
  var data = ar.map(function (e) {
    return {
      "errCodeId": e,
      "errCode": 'err00' + e,
      "errContent": Mock.mock('@csentence(5, 12)')
    };
  });
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": true,
      "timestamp": "2022-03-24T09:56:46.377+0000"
    },
    "data": {
      "data": data
    }
  };
});
Mock.mock('/mock_api/errCode/deleteErrCode', 'post', function () {
  var success = Math.random() > 0.2;
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": success,
      "timestamp": "2022-03-24T09:56:46.377+0000"
    },
    "data": {}
  };
});
Mock.mock('/mock_api/errCode/inssertErrCode', 'post', function () {
  var success = Math.random() > 0.2;
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": success,
      "timestamp": "2022-03-24T09:56:46.377+0000"
    },
    "data": {
      "id": Mock.mock('@natural')
    }
  };
});
Mock.mock('/mock_api/errCode/updateErrCode', 'post', function () {
  var success = Math.random() > 0.2;
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": success,
      "timestamp": "2022-03-24T09:56:46.377+0000"
    },
    "data": {}
  };
});
Mock.mock('/mock_api/error/deleteErrCodea', 'get', function () {
  var success = Math.random() > 0.2;
  return {
    "meta": {
      "msg": "success",
      "code": 6666,
      "success": success,
      "timestamp": "2022-03-24T09:56:46.377+0000"
    },
    "data": {}
  };
});