import "core-js/modules/es.array.map.js";
var avatars = ['https://gw.alipayobjects.com/zos/rmsportal/cnrhVkzwxjPwAaCfPbdc.png', 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png', 'https://gw.alipayobjects.com/zos/rmsportal/gaOngJwsRYRaVAuXXcmB.png', 'https://gw.alipayobjects.com/zos/rmsportal/WhxKECPNujWoWEFNdnJE.png', 'https://gw.alipayobjects.com/zos/rmsportal/ubnKSIfAJTxIgXOKlciN.png', 'https://gw.alipayobjects.com/zos/rmsportal/jZUIxmJycoymBprLOUbT.png'];
var positions = [{
  CN: 'Java工程师 | 蚂蚁金服-计算服务事业群-微信平台部',
  HK: 'Java工程師 | 螞蟻金服-計算服務事業群-微信平台部',
  US: 'Java engineer | Ant financial - Computing services business group - WeChat platform division'
}, {
  CN: '前端工程师 | 蚂蚁金服-计算服务事业群-VUE平台',
  HK: '前端工程師 | 螞蟻金服-計算服務事業群-VUE平台',
  US: 'Front-end engineer | Ant Financial - Computing services business group - VUE platform'
}, {
  CN: '前端工程师 | 蚂蚁金服-计算服务事业群-REACT平台',
  HK: '前端工程師 | 螞蟻金服-計算服務事業群-REACT平台',
  US: 'Front-end engineer | Ant Financial - Computing services business group - REACT platform'
}, {
  CN: '产品分析师 | 蚂蚁金服-计算服务事业群-IOS平台部',
  HK: '產品分析師 | 螞蟻金服-計算服務事業群-IOS平台部',
  US: 'Product analyst | Ant Financial - Computing services business group - IOS platform division'
}];
var sayings = ['那是一种内在的东西，他们到达不了，也无法触及的', '希望是一个好东西，也许是最好的，好东西是不会消亡的', '城镇中有那么多的酒馆，她却偏偏走进了我的酒馆', '那时候我只会想自己想要什么，从不想自己拥有什么'];
var logos = ['https://gw.alipayobjects.com/zos/rmsportal/WdGqmHpayyMjiEhcKoVE.png', 'https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png', 'https://gw.alipayobjects.com/zos/rmsportal/dURIMkkrRFpPgTuzkwnB.png', 'https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png', 'https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png'];
var admins = ['ICZER', 'JACK', 'LUIS', 'DAVID'];
var groups = ['高逼格设计天团', '中二少女团', '科学搬砖组', '骗你学计算机', '程序员日常'];
var users = [{
  name: '曲丽丽',
  avatar: avatars[0],
  groupId: 0
}, {
  name: '付晓晓',
  avatar: avatars[1],
  groupId: 0
}, {
  name: '林东东',
  avatar: avatars[2],
  groupId: 1
}, {
  name: '周星星',
  avatar: avatars[3],
  groupId: 2
}, {
  name: '朱偏右',
  avatar: avatars[4],
  groupId: 3
}, {
  name: '勒个',
  avatar: avatars[5],
  groupId: 4
}];
var teams = groups.map(function (item, index) {
  return {
    name: item,
    avatar: avatars[index]
  };
});
export { logos, sayings, positions, avatars, admins, groups, users, teams };